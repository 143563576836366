import { Component, Vue, Watch } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { AgGridVue } from 'ag-grid-vue';
import DatepickerRange from '@/components/ui/inputs/datepicker-range/datepickerRange.vue';
import Box from '@/components/ui/box/box.vue';
import BoxHeader from '@/components/ui/box/box-header/boxHeader.vue';
import GridFilter from '@/components/ui/grid-filter/gridFilter.vue';
import { AgGridHelper } from '@/helpers/ag-grid/ag-grid.helper';
import { Action, Getter } from 'vuex-class';
import { EventsRequest } from '@/types/request/events-request.types';
import { ListResponseType } from '@/types/response/list-response.type';
import { EventType } from '@/types/api/events/event.type';
import { ICallbackAdditionalParams, ICallbackGeneralParams } from '@/helpers/ag-grid/ag-grid-datasource';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { getDateFromISO } from '@/helpers/date.helper';
import { debounceFilter, hasFilter, setEmptyToNull } from '@/helpers/filter.helper';
import { MODAL_CONFIG } from '@/helpers/app.helper';
import ShowContent from '@/components/ui/modals/showContent.vue';
import visibleHeader from '@/components/ui/ag-grid/header-components/visible-header/visibleHeader.vue';
import PopoverPicker from '@/components/ui/inputs/popover-picker/popover-picker.vue';
import { getAppTypeLabel, getAppTypeList } from '@/constants/app.constants';

const title = 'events.title';

@Component({
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    AgGridVue,
    DatepickerRange,
    Box,
    BoxHeader,
    GridFilter,
    PopoverPicker
  },
})
export default class Events extends Vue {
  title = title;
  agGrid: AgGridHelper | null = null;
  filter: any = this.initFilter();
  selectedFlagsCount = 0;

  @Action('getEvents', { namespace: 'events' })
  getEvents!: (params: EventsRequest) => Promise<ListResponseType<EventType>>;

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Watch('currentLang')
  resetGridHeader(newValue: string, oldValue: string) {
    if (this.agGrid && newValue !== oldValue) {
      this.agGrid.getGridApi()!.refreshHeader();
    }
  }

  created(): void {
    this.debounceApplyFilter = debounceFilter(this.debounceApplyFilter);
  }

  beforeMount() {
    this.agGrid = new AgGridHelper(this.initGridOptions())
      .enableAutoSizeColumns(false)
      .enableInfinity(this.gridChangeCallback)
      .build();
  }

  gridChangeCallback(general: ICallbackGeneralParams, additional: ICallbackAdditionalParams) {
    additional.api.showLoadingOverlay();

    return this.getEvents({
      take: general.limit,
      skip: general.offset,
      ...setEmptyToNull(this.filter),
      ...(this.filter.app ? { app: [this.filter.app] } : {})
    })
      .then(response => {
        additional.api.hideOverlay();
        return {
          result: response.items,
          count: response.paging.totalCount
        }
      });
  }

  initGridOptions(): GridOptions {
    return {
      columnDefs: this.initColumnDef(),
      enableSorting: false,
      rowHeight: 100,
      headerHeight: 39,
      suppressHorizontalScroll: true,
    }
  }

  initColumnDef(): ColDef[] {
    return [
      {
        headerValueGetter: () => this.$t('events.nameAddress'),
        field: 'name',
        headerComponentFramework: visibleHeader,
        width: 400,
        minWidth: 400,
        cellRenderer: 'nameAddressCellRenderer',
        cellRendererParams: {
          imageUrl: (params: ICellRendererParams) => params.data && params.data.images?.[0]?.url,
          text: (params: ICellRendererParams) => params.data && params.data.name,
          url: (params: ICellRendererParams) => params.data ? `/event/update/${params.data.id}` : '#',
          geopoint: (params: ICellRendererParams) => params.data && params.data.geopoint,
        }
      },
      {
        headerValueGetter: () => this.$t('events.date'),
        field: 'date',
        headerComponentFramework: visibleHeader,
        width: 250,
        minWidth: 250,
        maxWidth: 250,
        cellRenderer: (params: ICellRendererParams) => {
          if (params.data) {
            const dateFrom = params.data.startAt ? getDateFromISO(params.data.startAt) : ''
            const dateTo = params.data.endAt ? getDateFromISO(params.data.endAt) : ''
            return `<div>${this.$t('common.from')}: ${dateFrom}</div>
              <div>${this.$t('common.to')}: ${dateTo}</div>
              <div>${this.$t('events.timezone')}: ${params.data.timezone}</div>`
          }
          return '';
        },
      },
      {
        headerValueGetter: () => this.$t('events.activities'),
        field: 'eventActivities',
        headerComponentFramework: visibleHeader,
        width: 400,
        minWidth: 400,
        maxWidth: 400,
        cellRenderer: 'activitiesCellRenderer',
        cellRendererParams: {
          activities: (params: ICellRendererParams) => params.data && params.data.eventActivities,
        },
        cellClass: 'ag-cell-w-100'
      },
      {
        headerValueGetter: () => this.$t('common.flags'),
        field: 'flags',
        headerComponentFramework: visibleHeader,
        width: 140,
        minWidth: 140,
        maxWidth: 140,
        cellRenderer: 'badgesCellRenderer',
        cellRendererParams: {
          items: (params: ICellRendererParams) => params.data && [
            { label: this.$t('events.isBlocked'), active: params.data.isBlocked },
            { label: this.$t('events.isOnline'), active: params.data.isOnline },
            { label: this.$t('events.isVIP'), active: params.data.isVIP },
            { label: this.$t('events.isJunketAllow'), active: params.data.isJunketAllow },
            { label: this.$t('events.featured'), active: params.data.featured },
          ],
        },
      },
      {
        headerValueGetter: () => this.$t('events.locales'),
        field: 'locales',
        headerComponentFramework: visibleHeader,
        width: 140,
        minWidth: 140,
        maxWidth: 140,
        cellRenderer: 'localesCellRenderer',
        cellRendererParams: {
          locales: (params: ICellRendererParams) => (params.data && params.data.locales) || [],
        }
      },
      {
        headerValueGetter: () => this.$t('events.appType'),
        field: 'appMeta',
        width: 250,
        minWidth: 250,
        maxWidth: 250,
        cellRenderer: (params: ICellRendererParams) => {
          if (params.data) {
            return params.data.appMeta.map(item => `<div class="badge mr-1 badge-light">${getAppTypeLabel(item)}</div>`).join('')
          }
          return '';
        },
      },
    ];
  }

  debounceApplyFilter() {
    this.applyFilter();
  }

  applyFilter() {
    if (this.agGrid) {
      this.agGrid.refreshInfinity();
    }
  }

  initFilter() {
    return {
      name: null,
      activity: null,
      dateFrom: null,
      dateTo: null,
      languageId: null,
      isOnline: null,
      isVIP: null,
      isJunketAllow: null,
      featured: null,
      isBlocked: null,
      app: null,
    };
  }

  get isFilter() {
    return hasFilter(this.filter);
  }

  showContent(title: string, content: string) {
    this.$modal.show(ShowContent, { content, title, }, MODAL_CONFIG);
  }

  onChangeFlag(value: boolean) {
    this.selectedFlagsCount += value ? 1 : -1;
    this.applyFilter();
  }

  get appTypeOptions() {
    return [
      { value: null, text: this.$t('events.anyType') },
      ...getAppTypeList()
    ];
  }
}
